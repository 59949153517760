export const APP_LINKS = {
  APP_STORE: 'https://apps.apple.com/us/app/quasark-crypto-wallet/id6449841456',
  GOOGLE_PLAY: 'https://play.google.com/store/apps/details?id=com.milkywayapps.cryptowallet',
  DISCORD: 'https://discord.com/invite/eKkrZWSXRd',
  TELEGRAM: 'https://t.me/quasark_channel',
  TWITTER: 'https://twitter.com/quasark_io',
  SUPPORT: 'support@quasark.io',
  ONE_LINK: 'https://onelink.to/q8yxgh',
  TOKENOMICS: 'https://docs.google.com/spreadsheets/d/1MGtTqy7UlpdNQkol7gip_5oKjD5LwogO',
};

export enum AppRoutes {
  MAIN = '/',
  WHITEPAPER = '/whitepaper',
  PRIVACY = 'privacy.html',
  TERMS = 'terms.html',
}
