import React from 'react';
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import Lottie from 'lottie-react';

import { ReactComponent as Subtract1 } from '../assets/images/roadmap/subtract_1.svg';
import { ReactComponent as Subtract2 } from '../assets/images/roadmap/subtract_2.svg';
import { ReactComponent as Subtract3 } from '../assets/images/roadmap/subtract_3.svg';
import roadmap_animation from '../assets/json/roadmap_animation.json';
import roadmap_animation_tablet from '../assets/json/roadmap_animation_tablet.json';

function Roadmap() {
  const isLessThan1000px = useMediaQuery('(max-width:1000px)');
  const theme = useTheme();

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <Box
        sx={{
          width: '100%',
          maxWidth: '1616px',
          padding: '20px',
        }}
      >
        <Box
          sx={{
            maxWidth: '100%',
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: 'var(--white)',
            borderRadius: '30px',
            overflow: 'hidden',
          }}
        >
          <Box
            sx={{
              paddingBottom: '100px',
              [theme.breakpoints.down(1000)]: {
                padding: '30px 20px 50px',
              },
            }}
          >
            <Typography
              sx={{
                fontFamily: 'Avenir600',
                color: 'var(--black)',
                fontSize: '64px',
                lineHeight: 'normal',
                marginBottom: '60px',
                paddingTop: '50px',
                paddingLeft: '50px',
                [theme.breakpoints.down(1000)]: {
                  fontSize: '36px',
                  marginBottom: '30px',
                  paddingTop: '0px',
                  paddingLeft: '0px',
                },
              }}
            >
              Roadmap
            </Typography>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                gap: '20px',
                [theme.breakpoints.down(1000)]: {
                  flexDirection: 'column',
                },
                '& .pointWrapper': {
                  width: '100%',
                  paddingLeft: '50px',
                  [theme.breakpoints.down(1000)]: {
                    flexDirection: 'row',
                    display: 'flex',
                    marginLeft: '-22px',
                    paddingLeft: '0px',
                  },
                  '& .pointHeader': {
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    gap: '20px',
                    [theme.breakpoints.down(1000)]: {
                      flexDirection: 'column',
                    },
                    '& svg': {
                      minWidth: '80px',
                      minHeight: '48px',
                      [theme.breakpoints.down(1000)]: {
                        transform: 'rotate(90deg)',
                        margin: '14px 8px',
                        minWidth: '66px',
                        width: '66px',
                        height: '40px',
                        minHeight: '40px',
                      },
                    },
                    '& .divider': {
                      width: '100%',
                      borderTop: '1px solid #d3d3d3',
                      [theme.breakpoints.down(1000)]: {
                        borderRight: '1px solid #d3d3d3',
                        height: '100%',
                        width: '1px',
                      },
                    },
                  },
                  '& .title': {
                    fontFamily: 'Avenir600',
                    color: 'var(--black)',
                    fontSize: '32px',
                    lineHeight: 'normal',
                    marginTop: '20px',
                    marginBottom: '15px',
                    [theme.breakpoints.down(1000)]: {
                      fontSize: '24px',
                    },
                  },
                },
                '& .listWrapper': {
                  [theme.breakpoints.down(1000)]: {
                    marginBottom: '30px',
                    // fontSize: '16px',
                  },
                  '& div:not(.divider)': {
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: '10px',
                    gap: '15px',
                    // border: '1px solid blue',
                    '& p': {
                      fontFamily: 'Avenir400',
                      color: 'var(--black)',
                      fontSize: '20px',
                      lineHeight: 'normal',
                      [theme.breakpoints.down(1000)]: {
                        fontSize: '16px',
                      },
                    },
                  },
                  '& .divider': {
                    borderTop: '1px solid #d3d3d3',
                    width: '20px',
                    [theme.breakpoints.down(1000)]: {
                      display: 'none',
                    },
                  },
                },
              }}
            >
              <Box className="pointWrapper">
                <Box className="pointHeader">
                  <Subtract1></Subtract1>
                  <Box className="divider"></Box>
                </Box>
                <Box>
                  <Typography className="title">Quarter 4, 2023</Typography>
                  <Box className="listWrapper">
                    <Box>
                      <Box className="divider"></Box>
                      <Typography>Closed Beta test + Open beta test</Typography>
                    </Box>
                    <Box>
                      <Box className="divider"></Box>
                      <Typography>In-app encrypted chat + transfers</Typography>
                    </Box>
                    <Box>
                      <Box className="divider"></Box>
                      <Typography>On-ramp & Swap solution</Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box className="pointWrapper">
                <Box className="pointHeader">
                  <Subtract2></Subtract2>
                  <Box className="divider"></Box>
                </Box>
                <Box>
                  <Typography className="title">Quarter 1, 2024</Typography>
                  <Box className="listWrapper">
                    <Box>
                      <Box className="divider"></Box>
                      <Typography>Anonymous transfers</Typography>
                    </Box>
                    <Box>
                      <Box className="divider"></Box>
                      <Typography>Community growth up to 500k users</Typography>
                    </Box>
                    <Box>
                      <Box className="divider"></Box>
                      <Typography>Initial Marketing and PR</Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box className="pointWrapper">
                <Box className="pointHeader">
                  <Subtract3></Subtract3>
                  <Box className="divider" sx={{ visibility: 'hidden' }}></Box>
                </Box>
                <Box>
                  <Typography className="title">Quarter 2, 2024</Typography>
                  <Box className="listWrapper">
                    <Box>
                      <Box className="divider"></Box>
                      <Typography>Games and dApps integration</Typography>
                    </Box>
                    <Box>
                      <Box className="divider"></Box>
                      <Typography>Web3 projects collabs</Typography>
                    </Box>
                    <Box>
                      <Box className="divider"></Box>
                      <Typography>QSRK token IEO and listing</Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              [theme.breakpoints.down(600)]: {
                margin: '0 calc(-100vw / 3.7)',
              },
            }}
          >
            <Lottie
              style={{ marginBottom: '-3px' }}
              animationData={isLessThan1000px ? roadmap_animation_tablet : roadmap_animation}
              loop={true}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default Roadmap;
