import React, { useRef, useState } from 'react';
import { Box, Button, Typography, useMediaQuery, useTheme } from '@mui/material';
import Carousel from 'react-multi-carousel';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';

import { ReactComponent as Arrow } from '../assets/images/svg/arrow_left.svg';
import { ReactComponent as AppStore } from '../assets/images/social/review_app_store.svg';
import { ReactComponent as GooglePlay } from '../assets/images/social/review_google_play.svg';

enum Platform {
  'appStore',
  'googlePlay',
}

const Card = ({ review }: { review: { name: string; text: string; platform: Platform } }) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        borderRadius: '30px',
        background: '#181818',
        margin: '0 12px',
        userSelect: 'none',
        height: '100%',
      }}
    >
      <Box
        sx={{
          height: '100%',
          minHeight: '616px',
          display: 'flex',
          flexDirection: 'column',
          [theme.breakpoints.down(1000)]: { minHeight: '405px' },
        }}
      >
        <Box flexGrow={1} sx={{ padding: '50px 50px 0', [theme.breakpoints.down(1000)]: { padding: '30px 20px 0' } }}>
          <Typography
            sx={{
              fontFamily: 'Avenir500',
              fontSize: '64px',
              color: 'var(--gold)',
              lineHeight: 'normal',
              [theme.breakpoints.down(1000)]: { fontSize: '36px' },
            }}
          >
            “
          </Typography>
          <Typography
            sx={{
              fontFamily: 'Avenir600',
              fontSize: '32px',
              color: 'var(--white)',
              lineHeight: 'normal',
              [theme.breakpoints.down(1000)]: { fontSize: '24px' },
            }}
          >
            {review.text}
          </Typography>
        </Box>
        <Box
          sx={{
            padding: '20px 50px 50px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            [theme.breakpoints.down(1000)]: { padding: '20px' },
          }}
        >
          <Typography
            sx={{
              fontFamily: 'Avenir500',
              fontSize: '24px',
              color: 'var(--white)',
              lineHeight: 'normal',
              [theme.breakpoints.down(1000)]: { fontSize: '16px' },
            }}
          >
            {review.name}
          </Typography>
          <Box
            sx={{
              '& svg': {
                [theme.breakpoints.down(1000)]: {
                  width: '82px',
                  height: '22px',
                },
              },
            }}
          >
            {review.platform === Platform.appStore ? <AppStore /> : <GooglePlay />}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

function Reviews() {
  const carouselRef = useRef<Carousel>(null);
  const [nextSlide, setNextSlide] = useState(0);
  const theme = useTheme();
  const isLess1000 = useMediaQuery('(max-width:1000px)');
  const isLess675 = useMediaQuery('(max-width:675px)');

  const carouselResponsive = {
    superLargeDesktop: {
      breakpoint: { max: 5000, min: 1000 },
      items: 3,
    },
    // tablet: {
    //   breakpoint: { max: 999, min: 675 },
    //   items: 2,
    //   partialVisibilityGutter: 80,
    // },
    // mobile: {
    //   breakpoint: { max: 674, min: 300 },
    //   items: 1,
    //   partialVisibilityGutter: 60,
    // },
  };

  const REVIEWS = [
    {
      name: 'Ethan Reynolds',
      text: 'Effortless navigation and quick transactions make this wallet my go-to for convenience.',
      platform: Platform.appStore,
    },
    {
      name: 'Ava Sullivan',
      text: 'Multi-factor authentication and biometric login ensure top-notch security against potential threats.',
      platform: Platform.googlePlay,
    },
    {
      name: 'Luna Carter',
      text: 'Robust encryption and cold storage make this wallet a secure haven for digital assets.',
      platform: Platform.appStore,
    },
    {
      name: 'Oliver Parker',
      text: 'An intuitive design paired with advanced security measures provides a perfect balance for users.',
      platform: Platform.appStore,
    },
    {
      name: 'Mia Fletcher',
      text: 'This wallet is highly recommended for its secure platform and built-in features, offering peace of mind for all levels of investors.',
      platform: Platform.googlePlay,
    },
    {
      name: 'Noah Harrison',
      text: 'With top-tier encryption and airtight security protocols, this wallet acts as an impregnable shield, safeguarding digital assets against potential threats.',
      platform: Platform.appStore,
    },
    {
      name: 'Zoe Mitchell',
      text: 'This wallet ensures both speed and security in transactions, making it a reliable choice for those who value efficiency without compromising safety.',
      platform: Platform.googlePlay,
    },
  ];

  return (
    <>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            width: '100%',
            maxWidth: '1656px',
          }}
        >
          <Box
            sx={{
              maxWidth: '100%',
              display: 'flex',
              flexDirection: 'column',
              padding: '20px',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginBottom: '40px',
                marginTop: '120px',
                [theme.breakpoints.down(1000)]: { marginBottom: '10px', marginTop: '80px' },
              }}
            >
              <Typography
                sx={{
                  fontFamily: 'Avenir600',
                  color: 'var(--white)',
                  fontSize: '64px',
                  lineHeight: 'normal',
                  [theme.breakpoints.down(1000)]: { fontSize: '36px' },
                }}
              >
                What our users say
              </Typography>
              {!isLess1000 && (
                <Box display={'flex'} gap={'15px'}>
                  <Button
                    disabled={nextSlide === 0}
                    variant="contained"
                    sx={{
                      borderRadius: '50%',
                      width: '66px',
                      height: '66px',
                      backgroundColor: 'var(--white)',
                      transition: 'all 0.3s ease',
                      '&:hover': {
                        opacity: 0.8,
                        backgroundColor: 'var(--white)',
                      },
                      '&:disabled': {
                        backgroundColor: 'var(--white)',
                      },
                    }}
                    onClick={() => {
                      if (carouselRef && carouselRef.current) {
                        carouselRef.current.goToSlide(nextSlide - 1);
                      }
                    }}
                  >
                    <Arrow></Arrow>
                  </Button>
                  <Button
                    disabled={nextSlide === 4}
                    variant="contained"
                    sx={{
                      transform: 'rotate(180deg)',
                      borderRadius: '50%',
                      width: '66px',
                      height: '66px',
                      backgroundColor: 'var(--white)',
                      transition: 'all 0.3s ease',
                      '&:hover': {
                        opacity: 0.8,
                        backgroundColor: 'var(--white)',
                      },
                      '&:disabled': {
                        backgroundColor: 'var(--white)',
                      },
                    }}
                    onClick={() => {
                      if (carouselRef && carouselRef.current) {
                        carouselRef.current.goToSlide(nextSlide + 1);
                      }
                    }}
                  >
                    <Arrow></Arrow>
                  </Button>
                </Box>
              )}
            </Box>
          </Box>

          <Box
            sx={{
              maxWidth: '100%',
              display: 'flex',
              flexDirection: 'column',
              padding: '0 8px',
            }}
          >
            {isLess1000 ? (
              <Box
                sx={{
                  maxWidth: '100%',
                }}
              >
                <Swiper spaceBetween={5} modules={[Pagination]} slidesPerView={isLess675 ? 1.25 : 2.45}>
                  {REVIEWS.map((el, index) => (
                    <SwiperSlide key={index}>
                      <Card review={el} key={el.name}></Card>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </Box>
            ) : (
              <Box
                sx={{
                  maxWidth: '100%',
                  '& .carousel': {
                    overflow: 'visible',
                  },
                }}
              >
                <Carousel
                  className="carousel"
                  partialVisible
                  ref={carouselRef}
                  arrows={false}
                  beforeChange={(nextSlide) => {
                    setNextSlide(nextSlide);
                  }}
                  responsive={carouselResponsive}
                >
                  {REVIEWS.map((el, index) => (
                    <Card review={el} key={el.name}></Card>
                  ))}
                </Carousel>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default Reviews;
