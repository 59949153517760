import React, { useEffect, useState } from 'react';
import { Box, useMediaQuery, useTheme } from '@mui/material';

import Header from '../components/Header';
import UnlockThePower from '../components/UnlockThePower';
import MainFeatures from '../components/MainFeatures';
import PoweredBy from '../components/PoweredBy';
import Features from '../components/Features';
import Roadmap from '../components/Roadmap';
import CryptoCoins from '../components/CryptoCoins';
import MeetOurTeam from '../components/MeetOurTeam';
import Reviews from '../components/Reviews';
import Applications from '../components/Applications';
import Footer from '../components/Footer';
import DownloadApplicationsModal from '../components/DownloadApplicationsModal';

import desktop_header_background from '../assets/images/header/desktop_header_background.webp';
import tablet_header_background2 from '../assets/images/header/tablet_header_background2.webp';
import mobile_header_background from '../assets/images/header/mobile_header_background.webp';
import { useLenis } from '@studio-freight/react-lenis';

function Main() {
  const theme = useTheme();
  const [isDownloadAppModalOpen, setDownloadAppModalOpen] = useState(false);
  const isLessThan1000px = useMediaQuery('(max-width:1000px)');
  const lenis = useLenis();

  useEffect(() => {
    if (lenis) {
      lenis.scrollTo(0, { immediate: true });
    }
  }, [lenis]);

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        backgroundImage: `url(${desktop_header_background})`,
        backgroundSize: '2400px 1200px',
        backgroundPosition: 'top',
        backgroundRepeat: 'no-repeat',
        backgroundColor: '#090909',
        paddingTop: '90px',
        overflow: 'hidden',

        [theme.breakpoints.down(1000)]: {
          backgroundImage: `url(${tablet_header_background2})`,
          backgroundSize: '992px 1200px',
        },
        [theme.breakpoints.down(600)]: {
          backgroundImage: `url(${mobile_header_background})`,
          backgroundSize: 'contain',
        },
      }}
    >
      <Header setDownloadAppModalOpen={setDownloadAppModalOpen}></Header>
      <UnlockThePower></UnlockThePower>
      <MainFeatures></MainFeatures>
      <PoweredBy></PoweredBy>
      <Features></Features>
      <Roadmap></Roadmap>
      <CryptoCoins></CryptoCoins>
      <MeetOurTeam></MeetOurTeam>
      <Reviews></Reviews>
      {!isLessThan1000px && <Applications></Applications>}
      <Footer></Footer>

      <DownloadApplicationsModal
        isDownloadAppModalOpen={isDownloadAppModalOpen}
        setDownloadAppModalOpen={setDownloadAppModalOpen}
      ></DownloadApplicationsModal>
    </Box>
  );
}

export default Main;
