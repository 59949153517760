import React from 'react';
import { Box, Link, Typography, useMediaQuery } from '@mui/material';
import { useLenis } from '@studio-freight/react-lenis';

import { APP_LINKS, AppRoutes } from '../utils/constants';
import { ReactComponent as ArrowUp } from '../assets/images/svg/arrow_up.svg';
import quasark from '../assets/images/svg/quasark.svg';

function Footer() {
  const lenis = useLenis();
  const isLessThan1000px = useMediaQuery('(max-width:1000px)');

  return (
    <>
      <Box
        sx={{
          width: '100%',
        }}
      ></Box>
      <Box
        sx={{
          width: '100%',
          marginTop: isLessThan1000px ? '80px' : '160px',
          background:
            'linear-gradient(180deg, rgba(226, 15, 0, 0.00) 0%, rgba(226, 49, 0, 0.25) 25%, rgba(227, 82, 0, 0.50) 50%, rgba(227, 116, 0, 0.75) 75%, #E39600 100%)',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            width: '100%',
            maxWidth: '1656px',
          }}
        >
          <Box
            sx={{
              maxWidth: '100%',
              display: 'flex',
              flexDirection: 'column',
              padding: '20px',
            }}
          >
            <img src={quasark} alt="quasark"></img>
            <Box
              sx={{
                display: isLessThan1000px ? 'block' : 'flex',
                justifyContent: 'space-between',
                marginTop: isLessThan1000px ? '50px' : '100px',
                marginBottom: isLessThan1000px ? '40px' : '80px',
                '& .link': {
                  fontFamily: 'Avenir400',
                  fontSize: isLessThan1000px ? '20px' : '24px',
                  color: 'var(--white)',
                  lineHeight: 'normal',
                  textDecoration: 'none',
                  width: 'max-content',
                },
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: isLessThan1000px ? 'column' : 'row',
                  gap: isLessThan1000px ? '15px' : '40px',
                  marginBottom: isLessThan1000px ? '40px' : undefined,
                }}
              >
                <Link className="link link--metis" href={`mailto:${APP_LINKS.SUPPORT}`}>
                  support@quasark.io
                </Link>
                <Link className="link link--metis" href={APP_LINKS.TWITTER} target="_blank">
                  Twitter
                </Link>
                <Link className="link  link--metis" href={APP_LINKS.DISCORD} target="_blank">
                  Discord
                </Link>
                <Link className="link  link--metis" href={APP_LINKS.TELEGRAM} target="_blank">
                  Telegram
                </Link>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: isLessThan1000px ? 'column' : 'row',
                  gap: isLessThan1000px ? '15px' : '40px',
                }}
              >
                <Link className="link  link--metis" href={AppRoutes.TERMS} target="_blank">
                  Terms of use
                </Link>
                <Link className="link  link--metis" href={AppRoutes.PRIVACY} target="_blank">
                  Privacy policy
                </Link>
              </Box>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '13px',
                '& div': {
                  width: '100%',
                  height: '2px',
                  background: 'var(--white)',
                },
              }}
            >
              {new Array(33).fill(null).map((el, index) => (
                <Box key={index}></Box>
              ))}
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                margin: '60px 0',
                '& p': {
                  fontFamily: 'Avenir400',
                  fontSize: isLessThan1000px ? '20px' : '24px',
                  color: 'var(--white)',
                  lineHeight: 'normal',
                },
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '40px',
                  width: '100%',
                  '& svg': {
                    width: '100%',
                    maxWidth: '62px',
                    '&:hover': {
                      transition: 'all 0.3s ease',
                      opacity: 0.8,
                      cursor: 'pointer',
                    },
                  },
                }}
              >
                <ArrowUp
                  onClick={() => {
                    lenis.scrollTo(0, { duration: 3 });
                  }}
                ></ArrowUp>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: isLessThan1000px ? 'column' : 'row',
                    gap: '10px',
                    width: '100%',
                    justifyContent: 'space-between',
                  }}
                >
                  <Typography
                    sx={{
                      display: 'block',
                      flexGrow: 1,
                    }}
                  >
                    SBS Studio Ltd. BVI Company Registration No. 2105408
                  </Typography>
                  <Typography>©2024</Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default Footer;
