import React, { useEffect, useLayoutEffect } from 'react';
import styled, { css } from 'styled-components';
import LogoImg from '../assets/images/logo.webp';
import web_bg from '../assets/images/whitepaper/web_bg.webp';
import mob_bg from '../assets/images/whitepaper/mob_bg.webp';
import zk_scheme from '../assets/images/whitepaper/zk_scheme.webp';
// import Bg1x from 'src/assets/images/whitepaper/background/background-1x.png';
// import Bg2x from 'src/assets/images/whitepaper/background/background-2x.png';

// import Bg1xM from 'src/assets/images/whitepaper/background/mobile/background-1x.png';
// import Bg2xM from 'src/assets/images/whitepaper/background/mobile/background-2x.png';

import { ReactComponent as ArrowLeftIcon } from '../assets/images/svg/arrow_right.svg';
// import ArrowLeftIcon from 'src/assets/svg/arrows/arrow-left-s.svg';

// import { Footer } from 'src/components/Layout/components';
// import { Picture } from 'src/components';
// import { useAllMedia } from 'src/hooks';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';
import { AppRoutes } from '../utils/constants';
import Footer from '../components/Footer';
import { useLenis } from '@studio-freight/react-lenis';

const Logo = styled.button`
  margin-top: 100px;
  background-color: transparent;
  border: 0;

  img {
    width: 320px;
    height: 80px;
  }
`;

const PictureWrapper = styled.div`
  position: relative;
  padding-top: 177%;
  margin-bottom: -275px;
  z-index: -1;

  img {
    display: block;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const Title = styled.h1`
  margin: 97px 0;
  font-style: normal;
  font-weight: 700;
  font-size: 64px;
  line-height: 87px;
  color: #f3f3f3;
  white-space: pre-wrap;
  font-family: Avenir600;
`;

const SectionTitle = styled.span`
  display: inline-block;
  margin-top: 133px;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 44px;
  color: #f3f3f3;
  font-family: Avenir600;
`;

const SectionText = styled.p`
  margin-top: 40px;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: normal;
  color: #878787;
  white-space: pre-wrap;
  font-family: Avenir400;
  span {
    margin-top: 20px;
    display: inline-block;
  }
`;

const SectionSubTitle = styled.strong`
  display: inline-block;
  font-weight: 600;
  font-size: 24px;
  line-height: 33px;
  color: #f3f3f3;
`;

const Container = styled.div`
  max-width: 1136px;
  margin: 0 auto;
  padding: 0 20px 0;
`;

const Wrapper = styled.div<{ $isMaxWidth768px: boolean }>`
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;

  .ml-10 {
    strong {
      font-weight: 400;
      color: white;
    }
  }

  a {
    text-decoration: none;
  }

  .cs-mt {
    margin-top: 73px;
  }

  .contrast {
    color: #b38c5f;
    font-weight: 600;
  }

  .cs-mt-s {
    margin-top: 100px;
  }

  .cs-mt-z {
    margin-top: 0;
  }

  ${(props) =>
    !props.$isMaxWidth768px
      ? css`
          .bg-image {
            max-width: 3000px;
            height: 800px;
            transform: translateX(-50%);
            left: 50%;
            top: 0;
            position: absolute;
            z-index: -1;
          }
        `
      : css`
          padding-bottom: 52px;

          ${Title} {
            margin: 0;
            font-size: 28px;
            line-height: 38px;
          }

          ${SectionTitle} {
            margin-top: 60px;
            font-size: 24px;
            line-height: 33px;
          }

          ${SectionText} {
            margin-top: 20px;
            font-size: 16px;
            line-height: 22px;
          }

          ${SectionSubTitle} {
            font-size: 16px;
            line-height: 22px;
          }

          ${Logo} {
            margin: 0;
            position: absolute;
            top: 20px;
            left: 20px;

            img {
              width: 160px;
              height: 40px;
            }
          }
        `}
`;

const ArrowButton = styled.button`
  margin-top: 40px;
  display: flex;
  align-items: center;
  background-color: transparent;
  border: 0;
  font-weight: 400;
  font-size: 20px;
  line-height: 27px;
  color: #878787;
  cursor: pointer;

  &:hover {
    color: #737373;
  }

  svg {
    margin-right: 15px;
  }
`;

const Navigation = styled.div<{ $isMobile: boolean; $show: boolean }>`
  display: flex;
  align-items: center;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  height: 67px;
  background: rgba(54, 54, 54, 0.8);
  backdrop-filter: blur(15.5172px);
  transition: all 0.2s ease;
  transform: translateY(-100%);

  button {
    margin: 0;
  }

  ${ArrowButton} {
    height: 67px;
    width: 100%;
    margin-top: 0;
  }

  ${(props) =>
    props.$isMobile &&
    css`
      top: initial;
      bottom: 0;
      height: 52px;
      transform: translateY(100%);

      ${ArrowButton} {
        font-size: 16px;
        line-height: 22px;
        height: 52px;
      }
    `}

  ${(props) =>
    props.$show &&
    css`
      transform: translate(0%);
    `}
`;

const NavigationInner = styled(Container)`
  width: 100%;
`;

function WhitePaper() {
  const navigate = useNavigate();
  const isMaxWidth768px = useMediaQuery('(max-width:768px)');
  const [showActionPanel, setShowActionPanel] = React.useState(false);
  const lenis = useLenis();

  React.useEffect(() => {
    const handler = () => {
      if (window.scrollY > 70) {
        setShowActionPanel(true);
      } else {
        setShowActionPanel(false);
      }
    };

    window.addEventListener('scroll', handler);

    return () => {
      window.removeEventListener('scroll', handler);
    };
  }, []);

  useLayoutEffect(() => {
    document.title = 'QuasarK White Paper';
  }, []);

  useEffect(() => {
    if (lenis) {
      lenis.scrollTo(0, { immediate: true });
    }
  }, [lenis]);

  return (
    <>
      <Wrapper $isMaxWidth768px={isMaxWidth768px}>
        {isMaxWidth768px ? (
          <PictureWrapper>
            <img src={mob_bg} alt="bg"></img>
          </PictureWrapper>
        ) : (
          <img className="bg-image" src={web_bg} alt="bg"></img>
        )}
        <Container>
          {!isMaxWidth768px && (
            <ArrowButton
              onClick={() => {
                navigate(AppRoutes.MAIN);
              }}
            >
              <ArrowLeftIcon style={{ transform: 'rotate(180deg)' }} />
              <span style={{fontFamily: 'Avenir400'}}>Back to main page</span>
            </ArrowButton>
          )}

          <Navigation $isMobile={isMaxWidth768px} $show={showActionPanel}>
            <NavigationInner>
              <ArrowButton
                onClick={() => {
                  navigate(AppRoutes.MAIN);
                }}
              >
                <ArrowLeftIcon style={{ transform: 'rotate(180deg)' }} />
                <span  style={{fontFamily: 'Avenir400'}}>Back to main page</span>
              </ArrowButton>
            </NavigationInner>
          </Navigation>

          <Logo
            onClick={() => {
              navigate(AppRoutes.MAIN);
            }}
          >
            <img src={LogoImg} alt="logo" />
          </Logo>
          <Title>{'Quasark.io\nProject Whitepaper'}</Title>
          <SectionTitle className="cs-mt-z">1. Technical Description of Quasark Wallet</SectionTitle>
          <SectionText>
            Quasark Wallet is a non-custodial, multi-chain wallet designed to provide users with an easy, secure way to
            manage their digital assets across different blockchains. Below we detail the essential technical features
            and functionalities.
          </SectionText>
          <SectionTitle>2. Non-Custodial Wallet</SectionTitle>
          <SectionText>
            As a non-custodial wallet, Quasark Wallet allows users to retain complete control over their private keys.
            This means that only the users have access to their funds, enhancing their financial sovereignty and
            privacy. The wallet encrypts and securely stores private keys on the user's device, ensuring that even if
            the wallet's servers were compromised, user funds would remain secure.
          </SectionText>
          <SectionTitle>3. Single Passphrase for Multi-Chain Support</SectionTitle>
          <SectionText>
            One unique feature of Quasark Wallet is the use of a single passphrase to generate wallets across all
            supported blockchains. This is achieved using a deterministic wallet standard called BIP39, which generates
            a master seed from a user's mnemonic phrase (passphrase). The seed can then be used to create individual
            wallets for each blockchain, streamlining the process of managing multiple blockchain assets.
          </SectionText>
          <SectionTitle>4. Token Swaps via Decentralized Exchanges (DEXs)</SectionTitle>
          <SectionText>
            Quasark Wallet incorporates DEX functionality, allowing users to swap tokens directly within the wallet
            interface. It leverages smart contract technology to interact directly with DEX protocols, executing trades
            on-chain without requiring users to deposit funds onto an exchange. This feature provides users with the
            convenience of trading their assets directly from their wallet, reducing the risk associated with
            centralized exchanges.
          </SectionText>
          <SectionTitle>5. Purchase of Cryptocurrencies Using Cards</SectionTitle>
          <SectionText>
            Quasark Wallet includes a feature that allows users to buy supported cryptocurrencies using their credit or
            debit cards. This functionality is enabled through integration with payment processing partners, which
            handle the card transactions and deliver the purchased cryptocurrency directly to the user's wallet. This
            feature simplifies the process of acquiring cryptocurrencies, making it more accessible for new users.
            {'\n\n'}
            The Quasark Wallet's design and architecture embody the principles of ease of use, security, and full user
            control over one's digital assets. It caters to both novice and experienced users, offering a seamless user
            experience while providing the tools needed for effective crypto asset management.
          </SectionText>

          <SectionTitle>6. Introduction to QSRK token</SectionTitle>
          <SectionText>
            The QSRK token is more than just a digital asset; it is a passport to a wide array of unique features and
            opportunities within the{' '}
            <a className="contrast" href="https://quasark.io" target="_blank" rel="noreferrer">
              Quasark.io
            </a>{' '}
            platform. It is designed to not only provide its holders with distinct advantages but also to integrate
            harmoniously with our platform's ecosystem, creating an environment that is conducive to efficient and
            equitable digital interactions. environment.
          </SectionText>
          <SectionText>
            <SectionSubTitle>6.1 Use Cases of QSRK</SectionSubTitle>
            <span className="ml-10">
              <strong>6.1.1 </strong>
              In-Chat Money TransfersQSRK stands out with its integration into our chat system, allowing users to make
              instantaneous money transfers while conversing. This functionality simplifies the process, eliminating the
              need for switching between applications and delivering a significant improvement over traditional methods
              in terms of speed, convenience, and efficiency.
            </span>
            <span className="ml-10">
              <strong>6.1.2 </strong> Anonymous TransfersTo ensure users' security and privacy, Quasark.io allows
              anonymous transfers with QSRK. Our robust security protocols and blockchain's inherent privacy features
              offer users the confidence to transact without the fear of their activities being tracked or traced,
              providing an enhanced level of security and privacy.
            </span>
            <span className="ml-10">
              <strong>6.1.3</strong> Access to Inbuilt LaunchpadQSRK holders gain exclusive access to our inbuilt
              launchpad, a feature that brings substantial benefits by offering early participation in new projects.
              This not only allows users to support promising ventures but also holds the potential for significant
              rewards, fostering a healthy and mutually beneficial ecosystem.
            </span>
          </SectionText>
          <SectionTitle>7. Anonymous transfers.</SectionTitle>
          <SectionText>
            <SectionSubTitle>7.1 Anonymous Transactions Through Zero-Knowledge Protocol</SectionSubTitle>
            <span>
              One of the revolutionary features of the Quasark Wallet is the provision for anonymous transactions.
              Utilizing the Zero-Knowledge Proof (ZKP) protocol, Quasark aims to bring unparalleled privacy and security
              to its users.
            </span>
          </SectionText>
          <SectionText>
            <SectionSubTitle>7.2. Understanding Zero-Knowledge Protocol</SectionSubTitle>
            <span>
              The Zero-Knowledge protocol is a cryptographic method by which one party (the prover) can prove to another
              party (the verifier) that they know a value x, without conveying any information apart from the fact that
              they know the value x.
            </span>
          </SectionText>
          <SectionText>
            <SectionSubTitle>7.3. Anonymous Transactions in Quasark</SectionSubTitle>
            <span>
              In the context of Quasark Wallet, the Zero-Knowledge protocol is applied to ensure transactional
              anonymity. When a user conducts a transaction, it can be verified without revealing any specific details
              to the public or even the network itself. Consequently, transactions are not directly accessible through a
              typical explorer, maintaining user privacy.
              {'\n\n'}
              Additionally, the overall amount involved in the transaction is also kept confidential, adding another
              layer of anonymity. Only the parties directly involved in the transaction have access to the total
              transaction sum.
            </span>
          </SectionText>
          <img style={{ maxWidth: '100%', margin: '40px 0' }} src={zk_scheme} alt="scheme"></img>
          <SectionText>
            <SectionSubTitle>7.4. Client-Side Verification</SectionSubTitle>
            <span>
              To ensure the integrity of these transactions, verification takes place on the client-side. This involves
              the user confirming the transaction with their passphrase and available balance. The passphrase acts as a
              proof that they have sufficient knowledge of the transaction without revealing the actual details. The
              balance further substantiates the legitimacy of the transaction without disclosing the exact transaction
              amount.
              {'\n\n'}
              Through this implementation of the Zero-Knowledge protocol, Quasark Wallet upholds its commitment to user
              privacy and security while still ensuring transactional integrity and transparency. Users can transact
              with confidence, knowing their privacy is protected by one of the most robust cryptographic protocols in
              existence.
            </span>
          </SectionText>
          <SectionTitle>8. Tokenomics</SectionTitle>
          <SectionText>
            The QSRK token will have a predefined total supply (5,000,000,000). The tokens' distribution will be
            strategically allocated to ensure long-term sustainability and balanced ecosystem growth. The token emission
            rate, along with mechanisms to control potential inflation or deflation, has been carefully considered and
            will be thoroughly managed to preserve the token's intrinsic value. Future measures such as token burns or
            buybacks may also be implemented to enhance QSRK's value proposition. Team is constantly working on token
            utility inside the ecosystem.
          </SectionText>
          <SectionTitle className="cs-mt-s">9. Roadmap</SectionTitle>
          <SectionText>
            <a className="contrast" href="https://quasark.io" target="_blank" rel="noreferrer">
              Quasark.io
            </a>
            's roadmap is a clear timeline of our ambitious goals, platform enhancements, and key milestones (in-wallet
            chat, ZK transfers, supporting more chains, building strong community). Our roadmap reflects our dedication
            to constant growth, both in terms of technology and community engagement. Each stage of the roadmap is
            meticulously planned to contribute meaningfully to our platform's evolution and the value of QSRK tokens.
          </SectionText>
          <SectionTitle>10. Conclusion</SectionTitle>
          <SectionText>
            <a className="contrast" href="https://quasark.io" target="_blank" rel="noreferrer">
              Quasark.io
            </a>{' '}
            differentiates itself through its unique blend of social interaction and digital finance, leveraging the
            power of the QSRK token to enable this vision. The platform and its token offer an exciting opportunity for
            potential investors and users who wish to be part of an innovative project that promises to redefine digital
            engagement. We warmly welcome everyone to engage with our vibrant community and explore the plethora of
            opportunities our project offers.
            {
              '\nPlease note that investing in cryptocurrencies involves a high degree of risk. Always do your own due diligence before making any investment decisions.'
            }
          </SectionText>
        </Container>
      </Wrapper>
      <Footer></Footer>
    </>
  );
}

export default WhitePaper;
