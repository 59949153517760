import React from 'react';
import { Box, Typography, useMediaQuery, Fade } from '@mui/material';

import { APP_LINKS } from '../utils/constants';

import close_svg from '../assets/images/svg/close.svg';
import download_qr from '../assets/images/social/qr.svg';
import download_app_store from '../assets/images/social/download_app_store.svg';
import download_google_play from '../assets/images/social/download_goole_play.svg';

function DownloadApplicationsModal({
  setDownloadAppModalOpen,
  isDownloadAppModalOpen,
}: {
  setDownloadAppModalOpen: (state: boolean) => void;
  isDownloadAppModalOpen: boolean;
}) {
  const isLess1450 = useMediaQuery('(max-width:1450px)');

  return (
    <Fade timeout={500} in={isDownloadAppModalOpen}>
      <Box
        sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          zIndex: 999,
        }}
      >
        <Box
          sx={{
            position: 'fixed',
            top: '40px',
            right: '40px',
            '&::-webkit-scrollbar': { display: 'none' },
            '&:hover': {
              cursor: 'pointer',
              opacity: 0.8,
            },
          }}
          onClick={() => setDownloadAppModalOpen(false)}
        >
          <img src={close_svg} alt="close"></img>
        </Box>
        <Box
          sx={{
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            background: 'var(--black)',
          }}
        >
          <Box
            sx={{
              width: '100%',
              maxWidth: '1416px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Box
              sx={{
                margin: '20px',
                maxWidth: isLess1450 ? '805px' : '100%',
                border: '1px solid var(--grey2)',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  borderBottom: '1px solid var(--grey2)',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <Box flexGrow={1} display={'flex'} alignItems={'center'} width={'803px'}>
                    <Typography
                      sx={{
                        fontFamily: 'Avenir600',
                        color: 'var(--white)',
                        fontSize: isLess1450 ? '48px' : '64px',
                        lineHeight: 'normal',
                        marginLeft: '50px',
                        maxWidth: '665px',
                      }}
                    >
                      Download the QuasarK app
                    </Typography>
                    {isLess1450 && <img width="286px" height="286px" src={download_qr} alt="qr code"></img>}
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      '& div': {
                        width: '50%',
                        height: '286px',
                        borderTop: '1px solid var(--grey2)',
                        '& img': {
                          transition: 'all 0.3s ease',
                          cursor: 'pointer',
                          marginLeft: '50px',
                          '&:hover': {
                            opacity: 0.8,
                          },
                        },
                      },
                    }}
                  >
                    <Box>
                      <Typography
                        sx={{
                          fontFamily: 'Avenir600',
                          color: 'var(--white)',
                          fontSize: '32px',
                          lineHeight: 'normal',
                          margin: '50px 50px 15px',
                        }}
                      >
                        iOS app
                      </Typography>
                      <Typography
                        sx={{
                          fontFamily: 'Avenir400',
                          color: 'var(--grey4)',
                          fontSize: '20px',
                          lineHeight: 'normal',
                          margin: '0 50px 20px',
                        }}
                      >
                        Available on all iPhones with iOS version higher than 15
                      </Typography>
                      <img
                        width="164px"
                        height="52px"
                        src={download_app_store}
                        alt="app store"
                        onClick={() => {
                          window.open(APP_LINKS.APP_STORE, '_blank');
                        }}
                      ></img>
                    </Box>
                    <Box
                      sx={{
                        borderLeft: '1px solid var(--grey2)',
                      }}
                    >
                      <Typography
                        sx={{
                          fontFamily: 'Avenir600',
                          color: 'var(--white)',
                          fontSize: '32px',
                          lineHeight: 'normal',
                          margin: '50px 50px 15px',
                        }}
                      >
                        Android app
                      </Typography>
                      <Typography
                        sx={{
                          fontFamily: 'Avenir400',
                          color: 'var(--grey4)',
                          fontSize: '20px',
                          lineHeight: 'normal',
                          margin: '0 50px 20px',
                        }}
                      >
                        Available on Android OS version higher than 8
                      </Typography>
                      <img
                        width="164px"
                        height="52px"
                        src={download_google_play}
                        alt="app store"
                        onClick={() => {
                          window.open(APP_LINKS.GOOGLE_PLAY, '_blank');
                        }}
                      ></img>
                    </Box>
                  </Box>
                </Box>
                {!isLess1450 && (
                  <Box
                    sx={{
                      minWidth: '571px',
                      height: '571px',
                      backgroundImage: `url(${download_qr})`,
                      backgroundSize: 'contain',
                      backgroundRepeat: 'no-repeat',
                      backgroundPosition: 'bottom',
                    }}
                  ></Box>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Fade>
  );
}

export default DownloadApplicationsModal;
