import React from 'react';
import { ReactLenis } from '@studio-freight/react-lenis';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import Main from './pages/Main';
import Whitepaper from './pages/Whitepaper';

import { AppRoutes } from './utils/constants';

import 'react-multi-carousel/lib/styles.css';
import 'swiper/css/pagination';
import 'swiper/css';

gsap.registerPlugin(ScrollTrigger);

const router = createBrowserRouter([
  {
    path: '*',
    element: <Main />,
  },
  {
    path: AppRoutes.WHITEPAPER,
    element: <Whitepaper></Whitepaper>,
  },
]);

console.log('build 0.1');

function App() {
  return (
    <ReactLenis root options={{ duration: 3 }}>
      <RouterProvider router={router} />
    </ReactLenis>
  );
}

export default App;
