import React, { useRef, useState } from 'react';
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useGSAP } from '@gsap/react';
import { useLenis } from '@studio-freight/react-lenis';
import gsap from 'gsap';

import { APP_LINKS } from '../utils/constants';
import arrow_right_svg from '../assets/images/svg/arrow_right.svg';
import arrows_webp from '../assets/images/poweredBy/arrows.webp';
import lines_tablet_webp from '../assets/images/poweredBy/lines_tablet.webp';
import tunnel_back from '../assets/images/poweredBy/tunnel_back.webp';
import tunnel_back_tablet from '../assets/images/poweredBy/tunnel_back_tablet.webp';
import tunnel_back_mob from '../assets/images/poweredBy/tunnel_back_mob.webp';
import tunnel_front from '../assets/images/poweredBy/tunnel_front.webp';
import tunnel_front_tablet from '../assets/images/poweredBy/tunnel_front_tablet.webp';
import tunnel_front_mob from '../assets/images/poweredBy/tunnel_front_mob.webp';
import rocket_svg from '../assets/images/poweredBy/rocket.svg';

function PoweredBy() {
  const isLess1656 = useMediaQuery('(max-width:1656px)');
  const isLess1000 = useMediaQuery('(max-width:1000px)');
  const isLess600 = useMediaQuery('(max-width:600px)');
  const [offsetDistance, setOffsetDistance] = useState(0);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const theme = useTheme();

  useLenis(({ scroll }) => {
    if (wrapperRef && wrapperRef.current) {
      let start = wrapperRef.current.offsetTop - window.innerHeight + window.innerHeight / 2;
      let finish = wrapperRef.current.offsetTop + 1187 - window.innerHeight / 2;

      if (scroll <= start) {
        return 0;
      } else if (scroll >= finish) {
        return 100;
      }

      const completedPercents = (scroll - start) / ((finish - start) / 100);

      setOffsetDistance(completedPercents);
    }
  });

  useGSAP(() => {
    if (isLess600) {
      gsap.to('.mobile_rocket_top', {
        rotate: '50deg',
        scrollTrigger: {
          trigger: '.mobile_rocket_top_wrapper',
          start: '-800px',
          end: '600px',
          scrub: 0,
          // markers: true,
          id: 'arrow_top',
        },
      });

      gsap.to('.mobile_rocket_bottom', {
        rotate: '50deg',
        scrollTrigger: {
          trigger: '.mobile_rocket_bottom_wrapper',
          start: '200px',
          end: '1700px',
          scrub: 0,
          // markers: true,
          id: 'arrow_bottom',
        },
      });
    }
    if (isLess1000 && !isLess600) {
      gsap.to('.tablet_rocket', {
        rotate: '360deg',
        scrollTrigger: {
          trigger: '.tablet_rocket_wrapper',
          start: '-600px',
          end: '1800px',
          scrub: 0,
          // markers: true,
          id: 'arrow_tablet',
        },
      });
    }
  }, {});

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      {isLess1000 ? (
        <Box
          width={'100%'}
          //position={'relative'}
        >
          {isLess600 ? (
            <>
              <Box
                sx={{
                  width: '100%',
                  height: '1020px',
                  display: 'flex',
                  position: 'absolute',
                  overflow: 'hidden',
                  backgroundImage: `url(${tunnel_back_mob})`,
                  backgroundSize: 'contain',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center top',

                  '&::after': {
                    backgroundImage: `url(${tunnel_front_mob})`,
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: `center top`,
                    position: 'absolute',
                    overflow: 'hidden',
                    content: '""',
                    display: 'block',
                    width: '100%',
                    height: '100%',
                    top: 0,
                    left: 0,
                  },
                }}
              >
                <Box
                  className="mobile_rocket_top_wrapper"
                  sx={{
                    width: '100%',
                    height: '100%',
                    transform: 'translateX(calc(50% - 92px)) translateY(calc(100vw / 8))',
                    position: 'relative',
                    '& .mobile_rocket_top': {
                      transform: 'rotate(-50deg)',
                      transformOrigin: '92px 510px',
                      position: 'absolute',
                    },
                  }}
                >
                  <img className="mobile_rocket_top" width="185" height="52" src={rocket_svg} alt="rocket"></img>
                </Box>
              </Box>
              <Box
                sx={{
                  maxWidth: '992px',
                  width: '100%',
                  height: '1020px',

                  display: 'flex',
                  position: 'absolute',
                  overflow: 'hidden',
                  backgroundImage: `url(${tunnel_back_mob})`,
                  backgroundSize: 'contain',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center top',
                  transform: 'rotate(180deg)',
                  '&::after': {
                    backgroundImage: `url(${tunnel_front_mob})`,
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: `center top`,
                    position: 'absolute',
                    overflow: 'hidden',
                    content: '""',
                    display: 'block',
                    width: '100%',
                    height: '100%',
                    top: 0,
                    left: 0,
                  },
                }}
              >
                <Box
                  className="mobile_rocket_bottom_wrapper"
                  sx={{
                    width: '100%',
                    height: '100%',
                    transform: 'translateX(calc(50% - 92px)) translateY(calc(100vw / 8))',
                    position: 'relative',
                    '& .mobile_rocket_bottom': {
                      transform: 'rotate(-50deg)',
                      transformOrigin: '92px 510px',
                      position: 'absolute',
                    },
                  }}
                >
                  <img className="mobile_rocket_bottom" width="185" height="52" src={rocket_svg} alt="rocket"></img>
                </Box>
              </Box>
            </>
          ) : (
            <Box
              sx={{
                maxWidth: '992px',
                width: '100%',
                minHeight: '1122px',
                display: 'flex',
                position: 'relative',
                backgroundImage: `url(${tunnel_back_tablet})`,
                backgroundSize: '992px 1122px',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'left',
                '&::after': {
                  backgroundImage: `url(${tunnel_front_tablet})`,
                  backgroundSize: '992px 1122px',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: `left`,
                  position: 'absolute',
                  overflow: 'hidden',
                  content: '""',
                  display: 'block',
                  width: '100%',
                  height: '100%',
                  top: 0,
                  left: 0,
                },
              }}
            >
              <Box
                className="tablet_rocket_wrapper"
                sx={{
                  width: '100%',
                  height: '100%',
                  position: 'absolute',
                  left: '170px',
                  transform: 'translateY(50px)',

                  '& .tablet_rocket': {
                    transform: 'rotate(-50deg)',
                    transformOrigin: '92px 511px',
                    position: 'absolute',
                  },
                }}
              >
                <img className="tablet_rocket" width="185" height="52" src={rocket_svg} alt="rocket"></img>
              </Box>
            </Box>
          )}

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '20px',
              paddingTop: '42px',
              paddingRight: '20px',
              marginTop: '20px',
              [theme.breakpoints.down(1000)]: {
                backgroundImage: `url(${lines_tablet_webp})`,
                backgroundSize: '481px 252px',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'right 20px top 0px',
              },
              [theme.breakpoints.down(600)]: {
                justifyContent: 'center',
                backgroundImage: 'none',
                gap: '10px',
                marginTop: '1000px',
              },
              '& div:not(.divider)': {
                fontFamily: 'Avenir400',
                color: 'var(--white)',
                fontSize: '20px',
                lineHeight: 'normal',
                padding: '15px 20px',
                border: '1px solid var(--grey2)',
                borderRadius: '50px',
                width: 'max-content',
                margin: '0 0 0 auto',
                [theme.breakpoints.down(600)]: {
                  margin: '0 auto',
                },
              },
              '& .divider': {
                height: '20px',
                borderRight: '1px solid var(--grey2)',
                margin: '0 auto',
                display: 'none',
                [theme.breakpoints.down(600)]: {
                  display: 'block',
                },
              },
            }}
          >
            <Box className="divider"></Box>
            <Box>Anonymous transfers</Box>
            <Box className="divider"></Box>
            <Box>Premium Messaging Benefits</Box>
            <Box className="divider"></Box>
            <Box>Built-in Launchpad</Box>
          </Box>
        </Box>
      ) : (
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            maxWidth: '2400px',
            height: '1187px',
            overflow: 'hidden',
          }}
        >
          <Box
            ref={wrapperRef}
            sx={{
              width: '2400px',
              height: '1187px',
              display: 'flex',
              justifyContent: 'center',
              position: 'absolute',
              backgroundImage: `url(${tunnel_back}), url(${arrows_webp})`,
              backgroundSize: '2400px 1187px, 1656px 567px',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: isLess1656
                ? `center, bottom 35px left calc(50% + 220px)`
                : `center, bottom 35px left calc(50% + 30px)`,
              overflow: 'hidden',

              '& #desktop_rocket': {
                position: 'absolute',
                inset: 0,
                marginTop: '100px',
                willChange: 'offset-distance',
                offsetPath:
                  "path('M-100 271.393 C158.297 92.707 348.292 1 616.899 1C1517.09 1 1499.81 987 2400 987H2766')",

                //animation
                offsetDistance: `${offsetDistance}%`,
                overflow: 'hidden',
              },
              '&::after': {
                backgroundImage: `url(${tunnel_front})`,
                backgroundSize: '2400px 1187px',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: `center`,
                position: 'absolute',
                overflow: 'hidden',
                content: '""',
                display: 'block',
                width: '100%',
                height: '100%',
                top: 0,
                left: 0,
              },
            }}
          >
            <img id="desktop_rocket" width="284" height="80" src={rocket_svg} alt="rocket"></img>
            <Box
              sx={{
                position: 'absolute',
                zIndex: 1,
                left: 0,
                width: '372px',
                height: '100%',
                background: 'linear-gradient(90deg, #090909 0%, rgba(9, 9, 9, 0.00) 100%)',
              }}
            ></Box>
            <Box
              sx={{
                position: 'absolute',
                zIndex: 1,
                right: 0,
                width: '372px',
                height: '100%',
                background: 'linear-gradient(90deg, #090909 0%, rgba(9, 9, 9, 0.00) 100%)',
                transform: 'rotate(180deg)',
              }}
            ></Box>
          </Box>
        </Box>
      )}

      <Box
        sx={{
          width: '100%',
          maxWidth: '1656px',
          position: 'absolute',
        }}
      >
        <Box
          sx={{
            maxWidth: '100%',
            display: 'flex',
            padding: '20px',
            flexDirection: 'column',
          }}
        >
          <Box
            sx={{
              maxWidth: '686px',
              [theme.breakpoints.down(1000)]: {
                maxWidth: '400px',
              },
              [theme.breakpoints.down(600)]: {
                maxWidth: '100%',
              },
            }}
          >
            <Typography
              sx={{
                fontFamily: 'Avenir600',
                color: 'var(--white)',
                fontSize: '64px',
                lineHeight: 'normal',
                marginTop: '320px',
                [theme.breakpoints.down(1000)]: {
                  fontSize: '36px',
                },
                [theme.breakpoints.down(600)]: {
                  marginTop: 'calc(100vw / 1.7)',
                },
              }}
            >
              Powered
              <br />
              by QSRK token
            </Typography>
            <Typography
              sx={{
                fontFamily: 'Avenir400',
                color: 'var(--grey4)',
                fontSize: '24px',
                margin: '30px 0',
                [theme.breakpoints.down(1000)]: {
                  fontSize: '20px',
                },
              }}
            >
              While key wallet functionality will be free and accessible to everyone, our premium users can benefit from
              extra benefits. Holders of QSRK token can get access to anonymous ZK-based transfers, premium messaging
              features as well as exclusive Launchpad access to invest in newly launched projects at special early
              valuations.
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '15px', visibility: 'hidden' }}>
              <Typography
                className="link link--metis"
                sx={{
                  fontFamily: 'Avenir600',
                  fontSize: '24px',
                  color: 'var(--white)',
                  lineHeight: 'normal',

                  textDecoration: 'underline',
                  textUnderlineOffset: '15px',
                  textDecorationThickness: '1px',
                  textDecorationColor: 'var(--grey2)',
                  cursor: 'pointer',
                  width: 'max-content',
                  transition: 'all 0.3s ease',
                  [theme.breakpoints.down(1000)]: {
                    fontSize: '20px',
                  },
                }}
                onClick={() => {
                  window.open(APP_LINKS.TOKENOMICS, '_blank');
                }}
              >
                Go to tokenomics
              </Typography>

              <img width="24px" height="24px" src={arrow_right_svg} alt="arrow"></img>
            </Box>
          </Box>
          {!isLess1000 && (
            <Box
              sx={{
                marginTop: '90px',
                '& div': {
                  fontFamily: 'Avenir400',
                  color: 'var(--white)',
                  fontSize: '24px',
                  lineHeight: 'normal',
                  padding: '20px 40px',
                  margin: '20px 0',
                  border: '1px solid var(--grey2)',
                  borderRadius: '50px',
                  width: 'max-content',
                },
              }}
            >
              <Box>Anonymous transfers</Box>
              <Box>Premium Messaging Benefits</Box>
              <Box>Built-in Launchpad</Box>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
}

export default PoweredBy;
