import React, { useState } from 'react';
import { Box, Button, Fade, Link, Typography, useMediaQuery } from '@mui/material';

import { APP_LINKS, AppRoutes } from '../utils/constants';

import logo from '../assets/images/logo.webp';
import short_logo from '../assets/images/short_logo.webp';
import x_svg from '../assets/images/social/x.svg';
import telegram_svg from '../assets/images/social/telegram.svg';
import discord_svg from '../assets/images/social/discord.svg';
import menu_svg from '../assets/images/svg/menu.svg';
import close_svg from '../assets/images/svg/close.svg';
import download_qr from '../assets/images/social/qr.svg';
import download_app_store from '../assets/images/social/download_app_store.svg';
import download_google_play from '../assets/images/social/download_goole_play.svg';

function Header({ setDownloadAppModalOpen }: { setDownloadAppModalOpen: (state: boolean) => void }) {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const isLessThan1000px = useMediaQuery('(max-width:1000px)');
  const isLessThan600px = useMediaQuery('(max-width:600px)');

  return (
    <>
      <Box
        sx={{
          width: '100%',
          maxWidth: '1656px',
          position: 'fixed',
          top: 0,
          zIndex: 999,
        }}
      >
        <Box
          sx={{
            maxWidth: '100%',
            display: 'flex',
            alignItems: 'center',
            padding: '20px',
          }}
        >
          <Box sx={{ flexGrow: 1 }}>
            <img width={176} height={44} src={isLessThan1000px ? short_logo : logo} alt="logo"></img>
          </Box>
          {isLessThan1000px ? (
            <>
              <img
                src={menu_svg}
                alt="menu"
                onClick={() => {
                  setMenuOpen(true);
                }}
                style={{ cursor: 'pointer' }}
              ></img>
            </>
          ) : (
            <>
              <Box
                sx={{
                  display: 'flex',
                  gap: '15px',
                  '& img': {
                    cursor: 'pointer',
                    transition: 'all 0.3s ease',
                    '&:hover': {
                      opacity: 0.8,
                    },
                  },
                }}
              >
                <img
                  onClick={() => {
                    window.open(APP_LINKS.TWITTER);
                  }}
                  src={x_svg}
                  alt="x"
                ></img>
                <img
                  onClick={() => {
                    window.open(APP_LINKS.DISCORD);
                  }}
                  src={discord_svg}
                  alt="discord"
                ></img>
                <img
                  onClick={() => {
                    window.open(APP_LINKS.TELEGRAM);
                  }}
                  src={telegram_svg}
                  alt="xtelegram"
                ></img>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  gap: '10px',
                  marginLeft: '20px',
                }}
              >
                <Button
                  sx={{
                    fontFamily: 'Avenir600',
                    fontSize: '16px',
                    textTransform: 'none',
                    padding: '15px 30px',
                    borderRadius: '10px',
                    backgroundColor: 'var(--white)',
                    color: 'var(--black)',
                    transition: 'all 0.3s ease',
                    lineHeight: 'normal',
                    letterSpacing: 'normal',
                    boxShadow: 'none',
                    '&:hover': {
                      backgroundColor: 'rgba(243, 243, 243, 0.80)',
                    },
                  }}
                  onClick={() => window.open(AppRoutes.WHITEPAPER, '_blank')}
                  variant="contained"
                >
                  Whitepaper
                </Button>
                <Button
                  sx={{
                    fontFamily: 'Avenir600',
                    fontSize: '16px',
                    textTransform: 'none',
                    padding: '15px 30px',
                    borderRadius: '10px',
                    backgroundColor: 'var(--gold)',
                    color: 'var(--white)',
                    transition: 'all 0.3s ease',
                    lineHeight: 'normal',
                    letterSpacing: 'normal',
                    boxShadow: 'none',
                    '&:hover': {
                      backgroundColor: '#9C7242',
                    },
                  }}
                  variant="contained"
                  onClick={() => setDownloadAppModalOpen(true)}
                >
                  Download the app
                </Button>
              </Box>
            </>
          )}
        </Box>
      </Box>
      <Fade in={isMenuOpen} timeout={500}>
        <Box
          sx={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            zIndex: 999,
            background: 'var(--black)',
            overflowX: 'scroll',
          }}
        >
          <Box
            sx={{
              padding: '20px',
              // height: '100%',
            }}
          >
            <Box display="flex" justifyContent="space-between" alignItems={'center'}>
              <img width={176} height={44} src={short_logo} alt="logo"></img>
              <img
                width={28}
                height={22}
                src={close_svg}
                alt="close"
                onClick={() => {
                  setMenuOpen(false);
                }}
                style={{ cursor: 'pointer' }}
              ></img>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: isLessThan600px ? 'column' : 'column-reverse',
                '& p': {
                  fontFamily: 'Avenir600',
                  fontSize: '36px',
                  lineHeight: 'normal',
                },
              }}
            >
              <Typography
                sx={{
                  color: 'var(--white)',
                  marginTop: '40px',
                }}
                onClick={() => window.open(AppRoutes.WHITEPAPER, '_blank')}
              >
                Whitepaper
              </Typography>
              {isLessThan600px ? (
                <Typography
                  sx={{
                    color: 'var(--gold)',
                    marginTop: '20px',
                  }}
                  onClick={() => window.open(APP_LINKS.ONE_LINK, '_blank')}
                >
                  Download the app
                </Typography>
              ) : (
                <Box sx={{ border: '1px solid var(--grey2)', marginTop: '40px' }}>
                  <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Typography
                      sx={{
                        fontFamily: 'Avenir600',
                        fontSize: '36px',
                        lineHeight: 'normal',
                        color: 'var(--white)',
                        margin: '40px',
                        maxWidth: '284px',
                      }}
                    >
                      Download the QuasarK app
                    </Typography>
                    <img width="244px" height="244px" src={download_qr} alt="qr code"></img>
                  </Box>
                  <Box
                    sx={{
                      '& .wrapper': { display: 'flex', justifyContent: 'space-between', padding: '40px' },
                      '& .textWrapper': { display: 'flex', flexDirection: 'column', gap: '5px' },
                      '& .title': {
                        color: 'var(--white)',
                        fontFamily: 'Avenir600',
                        fontSize: '24px',
                        lineHeight: 'normal',
                      },
                      '& .subTitle': {
                        color: 'var(--grey4)',
                        fontFamily: 'Avenir400',
                        fontSize: '16px',
                        lineHeight: 'normal',
                        marginRight: '40px',
                      },
                    }}
                  >
                    <Box
                      className="wrapper"
                      sx={{
                        borderTop: '1px solid var(--grey2)',
                      }}
                    >
                      <Box className="textWrapper">
                        <Typography className="title">iOS app</Typography>
                        <Typography className="subTitle">
                          Available on all iPhones with iOS version higher than 15
                        </Typography>
                      </Box>
                      <img
                        width="164px"
                        height="52px"
                        src={download_app_store}
                        alt="app store"
                        onClick={() => {
                          window.open(APP_LINKS.APP_STORE, '_blank');
                        }}
                      ></img>
                    </Box>
                    <Box
                      className="wrapper"
                      sx={{
                        borderTop: '1px solid var(--grey2)',
                      }}
                    >
                      <Box className="textWrapper">
                        <Typography className="title">Android app</Typography>
                        <Typography className="subTitle">Available on Android OS version higher than 8</Typography>
                      </Box>
                      <img
                        width="164px"
                        height="52px"
                        src={download_google_play}
                        alt="app store"
                        onClick={() => {
                          window.open(APP_LINKS.GOOGLE_PLAY, '_blank');
                        }}
                      ></img>
                    </Box>
                  </Box>
                </Box>
              )}
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '15px',
                borderTop: '1px solid var(--grey2)',
                marginTop: '40px',
                paddingTop: '40px',
                paddingBottom: '40px',
                '& .link': {
                  fontFamily: 'Avenir400',
                  fontSize: isLessThan1000px ? '20px' : '24px',
                  color: 'var(--white)',
                  lineHeight: 'normal',
                  textDecoration: 'none',
                  cursor: 'pointer',
                  width: 'max-content',
                },
              }}
            >
              <Link className="link link--metis" href={`mailto:${APP_LINKS.SUPPORT}`}>
                support@quasark.io
              </Link>
              <Link className="link link--metis" href={APP_LINKS.TWITTER} target="_blank">
                Twitter
              </Link>
              <Link className="link link--metis" href={APP_LINKS.DISCORD} target="_blank">
                Discord
              </Link>
              <Link className="link link--metis" href={APP_LINKS.TELEGRAM} target="_blank">
                Telegram
              </Link>
              <Link marginTop={'25px'} className="link link--metis" href={AppRoutes.TERMS} target="_blank">
                Terms of use
              </Link>
              <Link className="link link--metis" href={AppRoutes.PRIVACY} target="_blank">
                Privacy policy
              </Link>
            </Box>
          </Box>
        </Box>
      </Fade>
    </>
  );
}

export default Header;
