import React, { useState } from 'react';
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import { ReactComponent as PhoneBg } from '../assets/images/features/phone_bg.svg';
import { ReactComponent as Phone } from '../assets/images/features/phone.svg';
import { ReactComponent as Feature1 } from '../assets/images/features/feature_1.svg';
import { ReactComponent as Feature2 } from '../assets/images/features/feature_2.svg';
import { ReactComponent as Feature3 } from '../assets/images/features/feature_3.svg';
import { ReactComponent as Feature4 } from '../assets/images/features/feature_4.svg';
import { ReactComponent as Feature5 } from '../assets/images/features/feature_5.svg';
import { ReactComponent as Feature6 } from '../assets/images/features/feature_6.svg';
import { ReactComponent as Feature7 } from '../assets/images/features/feature_7.svg';
import { ReactComponent as Feature8 } from '../assets/images/features/feature_8.svg';
import plus from '../assets/images/svg/plus.svg';

function Features() {
  const colors = ['#B38C5F', '#C67940', '#C26246', '#918D4F', '#498B7C', '#4E74EC', '#856AD3', '#B47DC2'];
  const [selectedColor, setSelectedColor] = useState(colors[0]);
  const theme = useTheme();
  const isLessThan1000px = useMediaQuery('(max-width:1000px)');

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <Box
        sx={{
          width: '100%',
          maxWidth: '1656px',
        }}
      >
        <Box
          sx={{
            maxWidth: '100%',
            display: 'flex',
            flexDirection: 'column',
            padding: '20px',
          }}
        >
          <Typography
            sx={{
              fontFamily: 'Avenir600',
              color: 'var(--white)',
              fontSize: '64px',
              lineHeight: 'normal',
              marginTop: '110px',
              marginBottom: '60px',
              [theme.breakpoints.down(1000)]: {
                marginTop: '80px',
                fontSize: '36px',
                marginBottom: '30px',
              },
            }}
          >
            Get your ultimate crypto toolkit {!isLessThan1000px && <br />}
            with powerful tools made for everyone.
          </Typography>

          <Box
            sx={{
              display: 'grid',
              gap: '40px 24px',
              gridTemplateAreas: "'longCard . .' 'longCard . .' '. . .'",
              [theme.breakpoints.down(1000)]: {
                display: 'flex',
                flexDirection: 'column',
                gap: '20px',
              },

              '& .cardWrapper': {
                backgroundColor: '#181818',
                borderRadius: '30px',
                position: 'relative',
                overflow: 'hidden',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                '& svg': {
                  paddingTop: '30px',
                  maxWidth: '100%',
                  [theme.breakpoints.down(1000)]: {
                    paddingTop: '20px',
                  },
                },
                '& .feature_image': {
                  path: {
                    transition: 'all 0.3s ease',
                    fill: selectedColor,
                  },
                },
                '& .phone': {
                  position: 'absolute',
                  left: 0,
                  top: 0,
                },
                '& .phone_bg': {
                  path: {
                    transition: 'all 0.3s ease',
                    fill: selectedColor,
                  },
                },
                '& .textWrapper': {
                  padding: '30px 50px 50px',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '20px',
                  flexGrow: 1,
                  [theme.breakpoints.down(1000)]: {
                    gap: '15px',
                    padding: '20px 20px 30px',
                  },
                },
                '& .countWrapper': {
                  display: 'flex',
                  alignItems: 'center',
                  gap: '15px',
                  '& p': {
                    fontFamily: 'Avenir600',
                    fontSize: '20px',
                    color: 'var(--white)',
                    [theme.breakpoints.down(1000)]: {
                      fontSize: '16px',
                    },
                  },
                  '& div': {
                    borderTop: '1px solid var(--grey1)',
                    width: '100%',
                  },
                },
                '& .title': {
                  fontFamily: 'Avenir600',
                  fontSize: '32px',
                  color: 'var(--white)',
                  lineHeight: 'normal',
                  [theme.breakpoints.down(1000)]: {
                    fontSize: '24px',
                  },
                },
                '& .subTitle': {
                  fontFamily: 'Avenir400',
                  fontSize: '20px',
                  color: 'var(--grey4)',
                  lineHeight: 'normal',
                  [theme.breakpoints.down(1000)]: {
                    fontSize: '16px',
                  },
                },
              },
            }}
          >
            {!isLessThan1000px && (
              <Box sx={{ gridArea: 'longCard' }} className="cardWrapper">
                <Box sx={{ position: 'relative' }}>
                  <PhoneBg className="phone_bg"></PhoneBg>
                  <Phone className="phone"></Phone>
                </Box>
                <Box width={'100%'}>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      padding: '70px 50px 50px',
                      gap: '12px',
                    }}
                  >
                    {colors.map((color) => (
                      <Box
                        key={color}
                        sx={{
                          background: color,
                          height: '72px',
                          borderRadius: '35px',
                          width: '100%',
                          cursor: 'pointer',
                          transition: 'all 0.3s ease',
                          outline: selectedColor === color ? '2px solid #181818' : undefined,
                          outlineOffset: selectedColor === color ? '-4px' : undefined,
                          backgroundPosition: 'center',
                          '&:hover':{
                            backgroundImage: selectedColor !== color ? `url(${plus})` : undefined,
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                          }
                        }}
                        onClick={() => setSelectedColor(color)}
                      ></Box>
                    ))}
                  </Box>
                </Box>
                <Box className="textWrapper">
                  <Box className="countWrapper">
                    <Typography>03</Typography>
                    <Box className="divider"></Box>
                  </Box>
                  <Typography className="title">Customize your wallet</Typography>
                  <Typography className="subTitle">
                    In a few taps, change the design of your crypto wallet to suit your current mood using the color
                    presets we have prepared
                  </Typography>
                </Box>
              </Box>
            )}

            <Box className="cardWrapper">
              <Feature1 className="feature_image"></Feature1>
              <Box className="textWrapper">
                <Box className="countWrapper">
                  <Typography>01</Typography>
                  <Box className="divider"></Box>
                </Box>
                <Typography className="title">Get started in a minute</Typography>
                <Typography className="subTitle">
                  Create a wallet or connect an existing one in a few taps. Manage your portfolio or get your first
                  crypto asset effortlessly
                </Typography>
              </Box>
            </Box>

            <Box className="cardWrapper">
              <Feature2 className="feature_image"></Feature2>
              <Box className="textWrapper">
                <Box className="countWrapper">
                  <Typography>02</Typography>
                  <Box className="divider"></Box>
                </Box>
                <Typography className="title">Security & decentralization</Typography>
                <Typography className="subTitle">
                  Take control of your entire DeFi and NFT portfolio, ensuring seamless management and accessibility.
                  Experience convenience, security, and innovation all in one place.
                </Typography>
              </Box>
            </Box>

            {isLessThan1000px && (
              <Box className="cardWrapper">
                <Feature8 className="feature_image"></Feature8>
                <Box className="textWrapper">
                  <Box className="countWrapper">
                    <Typography>03</Typography>
                    <Box className="divider"></Box>
                  </Box>
                  <Typography className="title">Customize your wallet</Typography>
                  <Typography className="subTitle">
                    In a few taps, change the design of your crypto wallet to suit your current mood using the color
                    presets we have prepared
                  </Typography>
                </Box>
              </Box>
            )}

            <Box className="cardWrapper">
              <Feature3 className="feature_image"></Feature3>
              <Box className="textWrapper">
                <Box className="countWrapper">
                  <Typography>04</Typography>
                  <Box className="divider"></Box>
                </Box>
                <Typography className="title">Buying crypto made easy</Typography>
                <Typography className="subTitle">
                  Effortlessly buy cryptocurrencies using your credit card and choose the most favorable deal that
                  aligns with your preferences and requirements.
                </Typography>
              </Box>
            </Box>

            <Box className="cardWrapper">
              <Feature4 className="feature_image"></Feature4>
              <Box className="textWrapper">
                <Box className="countWrapper">
                  <Typography>05</Typography>
                  <Box className="divider"></Box>
                </Box>
                <Typography className="title">Swap instantly</Typography>
                <Typography className="subTitle">
                  Optimize, update and grow your crypto portfolio. Swap tokens to diversify your holdings or whenever
                  you need to get a particular token in a few taps
                </Typography>
              </Box>
            </Box>

            <Box className="cardWrapper">
              <Feature5 className="feature_image"></Feature5>
              <Box className="textWrapper">
                <Box className="countWrapper">
                  <Typography>06</Typography>
                  <Box className="divider"></Box>
                </Box>
                <Typography className="title">Ledger support</Typography>
                <Typography className="subTitle">
                  Benefit from enhanced security with Ledger support, allowing you to seamlessly connect and manage your
                  assets using your preferred hardware wallet.
                </Typography>
              </Box>
            </Box>

            <Box className="cardWrapper">
              <Feature6 className="feature_image"></Feature6>
              <Box className="textWrapper">
                <Box className="countWrapper">
                  <Typography>07</Typography>
                  <Box className="divider"></Box>
                </Box>
                <Typography className="title">Prices & market trends</Typography>
                <Typography className="subTitle">
                  Stay ahead of the game with real-time data on asset prices and market trends. Access insightful charts
                  and information to make informed investment decisions.
                </Typography>
              </Box>
            </Box>

            <Box className="cardWrapper">
              <Feature7 className="feature_image"></Feature7>
              <Box className="textWrapper">
                <Box className="countWrapper">
                  <Typography>08</Typography>
                  <Box className="divider"></Box>
                </Box>
                <Typography className="title">Make use of dApps</Typography>
                <Typography className="subTitle">
                  Seamlessly connect and interact with various dApps (dApps) and unlock a wide range of exciting
                  possibilities within our wallet
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default Features;
