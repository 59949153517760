import React from 'react';
import { Box, Typography } from '@mui/material';
import { keyframes } from '@emotion/react';

import arrow_svg from '../assets/images/svg/applications_arrow.svg';
import long_arrow_svg from '../assets/images/svg/long_arrow.svg';
import { APP_LINKS } from '../utils/constants';

function Applications() {
  const keyframesLeft = keyframes`
  from {
    transform: translate(0, 0);
  }
  to {
    transform: translate(-1000%, 0);
  }
  `;

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <Box
        sx={{
          width: '100%',
          maxWidth: '1656px',
          marginTop: '140px',
        }}
      >
        <Box
          sx={{
            margin: '20px',
            maxWidth: '100%',
            border: '1px solid var(--grey2)',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              borderBottom: '1px solid var(--grey2)',
            }}
          >
            <Box
              sx={{
                width: '66%',
                height: '551px',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Typography
                sx={{
                  fontFamily: 'Avenir600',
                  color: 'var(--white)',
                  fontSize: '94px',
                  lineHeight: 'normal',
                  marginLeft: '50px',
                }}
              >
                Unlock the real power of the blockchain
              </Typography>
            </Box>
            <Box
              sx={{
                width: '34%',
                backgroundImage: `url(${arrow_svg})`,
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'bottom',
              }}
            ></Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <Box
              onClick={() => {
                window.open(APP_LINKS.APP_STORE, '_blank');
              }}
              sx={{
                width: '33%',
                overflow: 'hidden',
                display: 'flex',
                flexDirection: 'column',
                userSelect: 'none',
                '& .visibleArea': {
                  opacity: 0,
                  transition: 'all 0.3s ease',
                  animation: `${keyframesLeft} 50s infinite linear`,
                },
                '&:hover': {
                  cursor: 'pointer',
                  '& .visibleArea': {
                    opacity: 1,
                  },
                },
              }}
            >
              <Box
                sx={{
                  flexGrow: 1,
                  padding: '50px 50px 20px',
                  display: 'flex',
                  alignItems: 'flex-start',
                  gap: '10px',
                  '& p': {
                    maxWidth: '350px',
                  },
                }}
              >
                <Box>
                  <Typography
                    sx={{
                      fontFamily: 'Avenir600',
                      color: 'var(--white)',
                      fontSize: '32px',
                      lineHeight: 'normal',
                      marginBottom: '15px',
                    }}
                  >
                    iOS application
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: 'Avenir400',
                      color: 'var(--grey4)',
                      fontSize: '20px',
                      lineHeight: 'normal',
                    }}
                  >
                    Available on all iPhones with iOS version higher than 15
                  </Typography>
                </Box>
                <img style={{ marginTop: '8px' }} src={long_arrow_svg} alt="arrow"></img>
              </Box>
              <Typography
                className="visibleArea"
                sx={{
                  fontFamily: 'Avenir600',
                  color: 'var(--white)',
                  fontSize: '128px',
                  lineHeight: 'normal',
                  whiteSpace: 'nowrap',
                }}
              >
                App Store App Store App Store App Store App Store App Store App Store App Store App Store App Store App
                Store App Store App Store App Store App Store App Store App Store App Store App Store App Store
              </Typography>
            </Box>
            <Box
              onClick={() => {
                window.open(APP_LINKS.GOOGLE_PLAY, '_blank');
              }}
              sx={{
                width: '33%',
                minHeight: '358px',
                borderLeft: '1px solid var(--grey2)',
                overflow: 'hidden',
                display: 'flex',
                flexDirection: 'column',
                userSelect: 'none',

                '& .visibleArea': {
                  opacity: 0,
                  transition: 'all 0.3s ease',
                  animation: `${keyframesLeft} 50s infinite linear`,
                },
                '&:hover': {
                  cursor: 'pointer',
                  '& .visibleArea': {
                    opacity: 1,
                  },
                },
              }}
            >
              <Box
                sx={{
                  flexGrow: 1,
                  padding: '50px 50px 20px',

                  display: 'flex',
                  alignItems: 'flex-start',
                  gap: '10px',
                  '& p': {
                    maxWidth: '350px',
                  },
                }}
              >
                <Box>
                  <Typography
                    sx={{
                      fontFamily: 'Avenir600',
                      color: 'var(--white)',
                      fontSize: '32px',
                      lineHeight: 'normal',
                      marginBottom: '15px',
                    }}
                  >
                    Android application
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: 'Avenir400',
                      color: 'var(--grey4)',
                      fontSize: '20px',
                      lineHeight: 'normal',
                    }}
                  >
                    Available on all phones with Android OS version higher than 8
                  </Typography>
                </Box>
                <img style={{ marginTop: '8px' }} src={long_arrow_svg} alt="arrow"></img>
              </Box>
              <Typography
                className="visibleArea"
                sx={{
                  fontFamily: 'Avenir600',
                  color: 'var(--white)',
                  fontSize: '128px',
                  lineHeight: 'normal',
                  whiteSpace: 'nowrap',
                }}
              >
                Google Play Google Play Google Play Google Play Google Play Google Play Google Play Google Play Google
                Play Google Play Google Play Google Play Google Play Google PlayGoogle Play Google Play Google Play
              </Typography>
            </Box>
            <Box
              sx={{
                width: '34%',
                borderLeft: '1px solid var(--grey2)',
                overflow: 'hidden',
                display: 'flex',
                flexDirection: 'column',
                userSelect: 'none',
                '& .visibleArea': {
                  opacity: 0,
                  transition: 'all 0.3s ease',
                  animation: `${keyframesLeft} 50s infinite linear`,
                },
                '&:hover': {
                  '& .visibleArea': {
                    opacity: 1,
                  },
                },
              }}
            >
              <Box
                sx={{
                  flexGrow: 1,
                  padding: '50px 50px 20px',
                  display: 'flex',
                  alignItems: 'flex-start',
                  gap: '10px',
                  '& p': {
                    maxWidth: '350px',
                  },
                }}
              >
                <Box>
                  <Typography
                    sx={{
                      fontFamily: 'Avenir600',
                      color: 'var(--white)',
                      fontSize: '32px',
                      lineHeight: 'normal',
                      marginBottom: '15px',
                    }}
                  >
                    Chrome extension
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: 'Avenir400',
                      color: 'var(--grey4)',
                      fontSize: '20px',
                      lineHeight: 'normal',
                    }}
                  >
                    Will be available very soon. Stay tuned!
                  </Typography>
                </Box>
                <img style={{ marginTop: '8px' }} src={long_arrow_svg} alt="arrow"></img>
              </Box>
              <Typography
                className="visibleArea"
                sx={{
                  fontFamily: 'Avenir600',
                  color: 'var(--grey2)',
                  fontSize: '128px',
                  lineHeight: 'normal',
                  whiteSpace: 'nowrap',
                }}
              >
                Soon Soon Soon Soon Soon Soon Soon Soon Soon Soon Soon Soon Soon Soon Soon Soon Soon Soon Soon Soon Soon
                Soon Soon Soon Soon Soon Soon Soon Soon Soon Soon Soon Soon Soon Soon Soon Soon Soon Soon Soon Soon Soon
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default Applications;
