import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import { keyframes } from '@emotion/react';

import row1webp from '../assets/images/cryptoCoins/row_1.webp';
import row2webp from '../assets/images/cryptoCoins/row_2.webp';
import row3webp from '../assets/images/cryptoCoins/row_3.webp';
import row4webp from '../assets/images/cryptoCoins/row_4.webp';
import row5webp from '../assets/images/cryptoCoins/row_5.webp';
import row6webp from '../assets/images/cryptoCoins/row_6.webp';

function CryptoCoins() {
  const theme = useTheme();

  const getKeyframes = (width: number) => keyframes`
from {
  background-position-x: 0;
}
to {
  background-position-x: -${width}px;
}
`;

  return (
    <>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            width: '100%',
            maxWidth: '1656px',
          }}
        >
          <Box
            sx={{
              maxWidth: '100%',
              display: 'flex',
              flexDirection: 'column',
              padding: '0 20px',
            }}
          >
            <Typography
              sx={{
                fontFamily: 'Avenir600',
                color: 'var(--white)',
                fontSize: '64px',
                lineHeight: 'normal',
                marginTop: '120px',
                marginBottom: '50px',
                [theme.breakpoints.down(1000)]: { fontSize: '36px', marginTop: '80px', marginBottom: '30px' },
              }}
            >
              No forms, no selfies.
              <br />
              Trade crypto anytime with <span style={{ color: 'var(--gold)' }}>ease.</span>
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          gap: '20px',
          flexDirection: 'column',
          [theme.breakpoints.down(1000)]: { gap: '15px' },
        }}
      >
        <Box
          sx={{
            width: '100%',
            height: '80px',
            backgroundImage: `url(${row1webp})`,
            backgroundSize: '2640px 80px',
            animation: `${getKeyframes(2640)} 90s linear infinite`,
            willChange: 'background-position',
            [theme.breakpoints.down(1000)]: {
              height: '60px',
              backgroundImage: `url(${row4webp})`,
              backgroundSize: '1982px 60px',
              animation: `${getKeyframes(1982)} 90s linear infinite`,
            },
          }}
        ></Box>
        <Box
          sx={{
            width: '100%',
            height: '80px',
            backgroundImage: `url(${row2webp})`,
            backgroundSize: '2740px 80px',
            animation: `${getKeyframes(2740)} 120s linear infinite`,
            willChange: 'background-position',
            [theme.breakpoints.down(1000)]: {
              height: '60px',
              backgroundImage: `url(${row5webp})`,
              backgroundSize: '2055px 60px',
              animation: `${getKeyframes(2055)} 120s linear infinite`,
            },
          }}
        ></Box>
        <Box
          sx={{
            width: '100%',
            height: '80px',
            backgroundImage: `url(${row3webp})`,
            backgroundSize: '2542px 80px',
            animation: `${getKeyframes(2542)} 70s linear infinite`,
            willChange: 'background-position',
            [theme.breakpoints.down(1000)]: {
              height: '60px',
              backgroundImage: `url(${row6webp})`,
              backgroundSize: '1906px 60px',
              animation: `${getKeyframes(1906)} 70s linear infinite`,
            },
          }}
        ></Box>
      </Box>
    </>
  );
}

export default CryptoCoins;
