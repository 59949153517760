import React from 'react';
import { Box, Divider, Typography, useMediaQuery, useTheme } from '@mui/material';
import { keyframes } from '@emotion/react';

import { AppRoutes } from '../utils/constants';

import info_icon_svg from '../assets/images/svg/info_icon.svg';
import arrow_right_svg from '../assets/images/svg/arrow_right.svg';
import apps_1 from '../assets/images/apps/group_270.webp';
import apps_2 from '../assets/images/apps/group_271.webp';
import apps_3 from '../assets/images/apps/group_272.webp';
import apps_4 from '../assets/images/apps/group_273.webp';
import apps_5 from '../assets/images/apps/group_274.webp';

function MainFeatures() {
  const isLess1000 = useMediaQuery('(max-width:1000px)');
  const isLess600 = useMediaQuery('(max-width:600px)');
  const theme = useTheme();

  const keyframesUp = keyframes`
  from {
    background-position: center 0px;
  }
  to {
    background-position: center -7000px;
  }
`;

  return (
    <>
      <Box
        sx={{
          width: '100%',
          maxWidth: '1656px',
          marginTop: '160px',
          marginBottom: '40px',
          [theme.breakpoints.down(1000)]: {
            marginTop: '80px',
            marginBottom: '120px',
          },
        }}
      >
        <Box
          sx={{
            maxWidth: '100%',
            display: 'flex',
            alignItems: 'center',
            padding: '20px',
            flexDirection: 'column',
            [theme.breakpoints.down(1000)]: {
              gap: '80px',
            },
            '& .wrapper': {
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              gap: '166px',
              [theme.breakpoints.down(1320)]: {
                gap: '50px',
              },
              [theme.breakpoints.down(1000)]: {
                flexDirection: 'column',
              },
            },
            '& .textWrapper': {
              maxWidth: '662px',
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              [theme.breakpoints.down(1000)]: {
                maxWidth: '100%',
              },
              '& .infoWrapper': {
                fontFamily: 'Avenir600',
                fontSize: '20px',
                color: 'var(--white)',
                lineHeight: 'normal',
                width: '60px',
                height: '60px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundImage: `url(${info_icon_svg})`,
                backgroundSize: 'cover',
                marginBottom: '30px',
                [theme.breakpoints.down(1000)]: {
                  width: '48px',
                  height: '48px',
                  fontSize: '16px',
                },
                '& svg': {
                  width: '24px',
                  height: '24px',
                },
              },
              '& .title': {
                fontFamily: 'Avenir600',
                fontSize: '48px',
                color: 'var(--white)',
                lineHeight: 'normal',
                [theme.breakpoints.down(1000)]: {
                  fontSize: '36px',
                },
              },
              '& .subTitle': {
                fontFamily: 'Avenir400',
                fontSize: '24px',
                color: 'var(--grey)',
                lineHeight: 'normal',
                marginTop: '182px',
                marginBottom: '30px',
                flexGrow: 1,
                [theme.breakpoints.down(1000)]: {
                  fontSize: '20px',
                  marginTop: '30px',
                },
              },
              '& .link': {
                width: 'max-content',
                fontFamily: 'Avenir600',
                fontSize: '24px',
                color: 'var(--white)',
                lineHeight: 'normal',

                textDecoration: 'underline',
                textUnderlineOffset: '15px',
                textDecorationThickness: '1px',
                textDecorationColor: 'var(--grey2)',
                cursor: 'pointer',
                transition: 'all 0.3s ease',
                [theme.breakpoints.down(1000)]: {
                  fontSize: '20px',
                },
                '&:hover': {
                  // textDecorationColor: 'var(--white)',
                  // gap: '30px',
                },
              },
            },
            '& .videoWrapper': {
              padding: '0 20px',
              borderRadius: '30px',
              backgroundColor: '#191919',
              overflow: 'hidden',
              maxWidth: '100%',
              width: '100%',
              flexGrow: 1,
              display: 'flex',
              justifyContent: 'center',
              [theme.breakpoints.down(1000)]: {
                width: 'auto',
              },
              '& video': {
                width: '492px',
                maxWidth: '100%',
              },
            },
          }}
        >
          <Box className="wrapper">
            <Box className="textWrapper">
              <Box className="infoWrapper">01</Box>
              <Typography className="title">Anonymous transfers based on ZKrollup</Typography>
              <Typography className="subTitle">
                ZK-Rollup enables anonymous transfers on the blockchain. This ensures privacy while maintaining
                scalability and reducing transaction costs.
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '15px',
                }}
              >
                <Typography
                  className="link link--metis"
                  onClick={() => {
                    window.open(AppRoutes.WHITEPAPER, '_blank');
                  }}
                >
                  Learn more
                </Typography>
                <img width="24px" height="24px" src={arrow_right_svg} alt="arrow"></img>
              </Box>
            </Box>
            <Box className="videoWrapper">
              <video autoPlay loop muted playsInline preload="auto">
                <source src={`${window.location.origin}/send-loop.mp4`} type='video/mp4; codecs="hvc1"'></source>
                <source src={`${window.location.origin}/send-loop.webm`} type="video/webm"></source>
              </video>
            </Box>
          </Box>
          {!isLess1000 && (
            <Divider
              sx={{
                border: 'none',
                width: '100%',
                margin: '80px',
                height: '1px',
                background: '#3C3C3C',
              }}
              orientation="horizontal"
            ></Divider>
          )}
          <Box className="wrapper">
            <Box className="textWrapper">
              <Box className="infoWrapper">02</Box>
              <Typography className="title">Secure Messaging with Premium Features</Typography>
              <Typography className="subTitle">
                Secure built-in messaging will bring a number of features, some of which will be available to premium
                users and holders of QSRK.
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '15px',
                }}
              >
                <Typography
                  className="link link--metis"
                  onClick={() => {
                    window.open(AppRoutes.WHITEPAPER, '_blank');
                  }}
                >
                  Learn more
                </Typography>
                <img width="24px" height="24px" src={arrow_right_svg} alt="arrow"></img>
              </Box>
            </Box>
            <Box className="videoWrapper">
              <video autoPlay loop muted playsInline preload="auto">
                <source src={`${window.location.origin}/chat-loop.mp4`} type='video/mp4; codecs="hvc1"'></source>
                <source src={`${window.location.origin}/chat-loop.webm`} type="video/webm"></source>
              </video>
            </Box>
          </Box>
          {!isLess1000 && (
            <Divider
              sx={{
                border: 'none',
                width: '100%',
                margin: '80px',
                height: '1px',
                background: '#3C3C3C',
              }}
              orientation="horizontal"
            ></Divider>
          )}
          <Box className="wrapper">
            <Box className="textWrapper">
              <Box className="infoWrapper">03</Box>
              <Typography className="title">Built-in Launchpad and Community Driven Store</Typography>
              <Typography className="subTitle">
                All in one place: Dapps, NFTs, games, tokens, DeFi projects. Boundless crypto world with the built-in
                Launchpad for early access to great new projects.
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '15px',
                }}
              >
                <Typography
                  className="link link--metis"
                  onClick={() => {
                    window.open(AppRoutes.WHITEPAPER, '_blank');
                  }}
                >
                  Learn more
                </Typography>
                <img width="24px" height="24px" src={arrow_right_svg} alt="arrow"></img>
              </Box>
            </Box>
            <Box
              sx={{
                width: '100%',
                height: '576px',
                minWidth: '530px',
                borderRadius: '30px',
                display: 'flex',
                flexDirection: 'row',
                position: 'relative',
                gap: '32px',
                [theme.breakpoints.down(600)]: {
                  height: '366px',
                  minWidth: 'auto',
                  gap: '24px',
                },
                '& .imageWrapper': {
                  height: '100%',
                  width: '100%',
                  backgroundSize: 'cover',
                  backgroundRepeat: 'repeat-y',
                  backgroundPosition: 'center 0px',
                  willChange: 'background-position',
                },
                '&::after': {
                  background: 'linear-gradient(180deg, #090909 0%, rgba(9, 9, 9, 0.00) 100%)',
                  position: 'absolute',
                  content: '""',
                  display: 'block',
                  width: '100%',
                  height: '140px',
                  top: 0,
                },
                '&::before': {
                  background: 'linear-gradient(180deg, #090909 0%, rgba(9, 9, 9, 0.00) 100%)',
                  transform: 'rotateX(180deg)',
                  position: 'absolute',
                  content: '""',
                  display: 'block',
                  width: '100%',
                  height: '140px',
                  bottom: '-2px',
                },
              }}
            >
              <Box
                className="imageWrapper"
                sx={{
                  backgroundImage: `url(${apps_1})`,
                  animation: `${keyframesUp} 120s infinite linear`,
                }}
              ></Box>
              <Box
                className="imageWrapper"
                sx={{
                  backgroundImage: `url(${apps_2})`,
                  animation: `${keyframesUp} 140s infinite linear`,
                }}
              ></Box>
              <Box
                className="imageWrapper"
                sx={{
                  backgroundImage: `url(${apps_3})`,
                  animation: `${keyframesUp} 110s infinite linear`,
                }}
              ></Box>
              <Box
                className="imageWrapper"
                sx={{
                  backgroundImage: `url(${apps_4})`,
                  animation: `${keyframesUp} 130s infinite linear`,
                }}
              ></Box>
              {!isLess600 && (
                <Box
                  className="imageWrapper"
                  sx={{
                    backgroundImage: `url(${apps_5})`,
                    animation: `${keyframesUp} 150s infinite linear`,
                  }}
                ></Box>
              )}
            </Box>
          </Box>
          {!isLess1000 && (
            <Divider
              sx={{
                borderTop: '1px solid #3C3C3C',
                width: '100%',
                margin: '80px',
              }}
              orientation="horizontal"
            ></Divider>
          )}
        </Box>
      </Box>
    </>
  );
}

export default MainFeatures;
